@use '@styles/_partials' as *;

.headingBlockContainer {
  color: $white;
  text-align: center;
  margin: 0 auto;
  width: 100%;

  h1 {
    margin: pxToRem(20px) 0;
    text-transform: uppercase;
  }

  .imageContainer {
    position: relative;
    width: 220px;
    margin: auto;
    margin-bottom: pxToRem(60px);

    img {
      z-index: 10;
      position: relative;
      max-width: 220px;
      width: 100%;
      image-rendering: auto;

      @supports (image-rendering: -webkit-optimize-contrast) {
        image-rendering: -webkit-optimize-contrast;
      }
    }

    // circle gradient
    @include bgRadialGradient(435px);
  }

  .copyContainer {
    position: relative;
    z-index: 10;
    max-width: $containerMaxWidth;
    margin: 0 auto;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .header,
  .subheader,
  .imageContainer {
    opacity: 0;
  }

  .inViewport {
    .imageContainer {
      animation: revealItem forwards $animationDuration;
    }
    .header {
      animation: revealItem forwards $animationDuration
        calc($animationDelay - $animationDelayAddend);
    }
    .subheader {
      animation: revealItem forwards $animationDuration $animationDelay;
    }
  }
}
